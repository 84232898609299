<template>
  <div class="jurisdiction">
		<div class="search-box">
			<el-form :inline="true" :model="formSearch" class="demo-form-inline" label-width="100px">
				<el-form-item label="部门名称">
					<el-input v-model="formSearch.name" placeholder="请输入部门名称"></el-input>
				</el-form-item>
				<el-form-item label="租户名称">
					<el-select v-model="formSearch.tenantId" clearable>
						<el-option v-for="(item,index) in tenantList" :key="index" :label="item.projectName" :value="item.id"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="所属区域">
					<el-cascader v-model="formSearch.quyu" :options="quyuList" :props="quyuProps" ref="quyuRef" clearable change-on-select style="width: 100%;"></el-cascader>
				</el-form-item>
				<el-form-item style="text-align: right;" class="fr">
					<el-button type="primary" @click="handleSearch">检索</el-button>
					<el-button type="success" @click="handleAdd">新增</el-button>
				</el-form-item>
			</el-form>
		</div>
		<!-- 列表 -->
		<div class="table-box">
			<el-card>
				<el-table v-loading="loadingFlag" border :data="tableData" :row-style="{height:'48px'}" empty-text="加载中..." style="width: 100%;">
					<el-table-column type="index" :index="typeIndex" label="序号" width="50"></el-table-column>
					<el-table-column prop="name" label="部门名称"></el-table-column>
					<el-table-column prop="tenantName" label="租户名称"></el-table-column>
					<el-table-column prop="" label="所属区域">
						<template slot-scope="scope">
							<span>{{scope.row.province}} {{scope.row.city}} {{scope.row.area}}</span>
						</template>  
					</el-table-column>
					<el-table-column prop="updateBy" label="更新人员" width="100"></el-table-column>
					<el-table-column prop="updateTime" label="更新时间" width="200"></el-table-column>
					<el-table-column prop="status" label="状态" width="100">
						<template slot-scope="scope">
							<span v-if="scope.row.status == '0'">启用</span>
							<span v-if="scope.row.status == '1'">停用</span>
						</template>    
					</el-table-column>
					<el-table-column prop="" label="操作" width="100">
						<template slot-scope="scope">
							<span @click="handleEdit(scope.$index, scope.row)" style="cursor: pointer;" class="color-m">编辑</span>
							|
							<span @click="handDelete(scope.$index, scope.row)" style="color: red;cursor: pointer;">删除</span>
						</template>
					</el-table-column>
					<template slot="empty">
						<img v-if="dataTableEmpty" class="data-pic" :src="`${baseUrl}img/bt-nodata-now.png`" alt="" style="display: block;margin: 100px auto;" />
					</template>
				</el-table>
				<el-pagination @current-change="handleCurrentChange" :total="totalItems" :current-page="currentPage" :page-size="pageSize" layout="total, prev, pager, next, jumper"></el-pagination>
			</el-card>
		</div>
		<!-- 添加  编辑     弹框 -->
		<el-dialog :title="dialogTitle" :visible.sync="dialogVisible" width="30%">
			<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
				<el-form-item label="部门名称" prop="name">
					<el-input v-model="ruleForm.name" placeholder="请输入部门名称"></el-input>
				</el-form-item>
				<el-form-item label="租户名称" prop="tenantId">
					<el-select v-model="ruleForm.tenantId">
						<el-option v-for="(item,index) in tenantList" :key="index" :label="item.projectName" :value="item.id"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="注册省份" prop="provinceId">
					<el-select v-model="ruleForm.provinceId" @change="getCityList" clearable>
						<el-option v-for="(item,index) in ruleForm.provinceList" :key="index" :label="item.name" :value="item.code"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="注册地市" prop="cityId">
					<el-select v-model="ruleForm.cityId" @change="getDistrictList" clearable>
						<el-option v-for="(item,index) in ruleForm.cityList" :key="index" :label="item.name" :value="item.code"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="注册区县" prop="areaId">
					<el-select v-model="ruleForm.areaId" clearable>
						<el-option v-for="(item,index) in ruleForm.districtList" :key="index" :label="item.name" :value="item.code"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="状态" prop="status">
					<el-select v-model="ruleForm.status" :disabled="ruleForm.statusDisable">
						<el-option label="启用" value="0"></el-option>
						<el-option label="停用" value="1"></el-option>
					</el-select>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button type="primary" @click="submitForm('ruleForm')">确 定</el-button>
			</span>
		</el-dialog>
  </div>
</template>

<script>
export default {
  name: 'jurisdiction',
  data() {
		return {
			baseUrl:this.$pulick.baseUrl,//默认路径目录  加载静态文件img
			//省市区  层级选择器
			quyuList: [],//省市区   数据
			quyuProps: {
				lazy: true,
			},
			tenantList:[],
			formSearch: {
			name: '',
			tenantId: '',
				quyu: [],
			},
			loadingFlag: false,
			dataTableEmpty:false,
			tableData: [], //列表  数组
			currentPage: 1, //当前页
			pageSize: 15, //每页  条数
			totalItems: 0, //列表 总数
			dialogVisible: false,
			dialogTitle: "部门新增",
			disabledFlag:false,
			ruleForm: {
				id: '',
				name: '',
				tenantId: '',
				provinceId: '',
				cityId: '',
				areaId: '',
				status: '',
				statusDisable: false,
				provinceList:[],
				cityList:[],
				districtList:[],
			},
			rules: {
				name: [
					{ required: true, message: '请输入部门名称', trigger: 'blur' },
				],
			}
		};
  },
  mounted() {}, 
  created() {
		let _this = this; //this指向   重定义
		//省市区  层级选择器
		this.quyuProps.lazyLoad = function lazyLoad (node, resolve) { 
			//所对应节点数据
			const { level } = node;
			//获取下一节点数据
			setTimeout(() => {
				_this.$provider.get('/wg-system/depart/baseAreaList',{
					params:{
						level: level==0 ? 1 : node.level+1,
						parentId: level==0 ? '' : node.value,
					}
				}).then(res=>{
					const nodes = [];
					for(let i=0;i<res.data.length;i++){
						nodes.push({
							label:res.data[i].name,
							value:res.data[i].code,
							leaf: level >= 2 //控制层级
						});
					}
					// 通过调用resolve将子节点数据返回，通知组件数据加载完成
					resolve(nodes);
				})
			}, 500);
		}
		this.getPageList(); //加载列表
		this.getTenantList();//加载部门列表
		this.getProvinceList();//加载省份
  },
  methods: {
		//点击查询  按钮
		handleSearch() {
			this.currentPage = 1; //初始第一页
			this.getPageList(); //加载列表
		},
		//加载列表
		getPageList() {
			this.loadingFlag = true;//loading 显示
			this.dataTableEmpty = false;
			this.$provider.get('/wg-system/depart/page', {
				params: {
					name: this.formSearch.name,
					tenantId: this.formSearch.tenantId,
					provinceId: this.formSearch.quyu.length>=1 ? this.formSearch.quyu[0] : "",
					cityId: this.formSearch.quyu.length>=2 ? this.formSearch.quyu[1] : "",
					areaId: this.formSearch.quyu.length>=3 ? this.formSearch.quyu[2] : "",
					current: this.currentPage,
					size: this.pageSize,
				}
			}).then(res => {
				this.loadingFlag = false;//loading 消失
				this.dataTableEmpty = true;
				this.tableData = res.data.records;
				this.totalItems = res.data.total;
			})
		},
		//处理分页数据的 index
		typeIndex(index) {
			return (this.currentPage - 1) * this.pageSize + index + 1;
		},
		//当前页面  属于第几页
		handleCurrentChange(val) {
			this.currentPage = val;
			this.getPageList(); //加载列表
		},
		//删除
		handDelete(index,item){
			if(confirm("确定删除本条数据吗？")){
				this.$provider.get('/wg-system/depart/delete', {
					params: {
						ids: item.id,
					}
				}).then(res => {
					if (res.code == 200) {
						this.$message({
							message: '删除成功',
							type: 'success'
						});
						this.getPageList();
					} else {
						this.$message.error('删除失败');
						return false;
					}
				})
			}
		},
		//编辑  弹框打开
		handleEdit(index,item) {
			this.dialogVisible = true; //打开弹框
			this.dialogTitle = "部门编辑";
			this.ruleForm.id = item.id;
			this.ruleForm.name = item.name;
			this.ruleForm.tenantId = item.tenantId;
			this.ruleForm.provinceId = item.provinceId!=null ? Number(item.provinceId) : "";
			this.ruleForm.cityId = item.cityId!=null ? Number(item.cityId) : "";
			this.ruleForm.areaId = item.areaId!=null ? Number(item.areaId) : "";
			this.ruleForm.status = item.status;
			this.ruleForm.statusDisable = false;
			if(item.provinceId != null){
				this.getCityList(item.provinceId);//市份
			}
			if(item.cityId != null){
				this.getDistrictList(item.cityId);//区份
			}
		},
		//添加  弹框打开
		handleAdd() {
			this.dialogVisible = true; //打开弹框
			this.dialogTitle = "部门新增";
			this.ruleForm.id = '';
			this.ruleForm.name = '';
			this.ruleForm.tenantId = '';
			this.ruleForm.provinceId = '';
			this.ruleForm.cityId = '';
			this.ruleForm.areaId = '';
			this.ruleForm.status = '0';
			this.ruleForm.statusDisable = true;
		},
		//加载租户列表
		getTenantList() {
			this.$provider.get('/wg-system/tenant/list', {}).then(res => {
				this.tenantList = res.data;
			})
		},
		//获取省份
		getProvinceList(){
			this.$provider.get('/wg-system/depart/baseAreaList',{
				params:{
					level: 1,
					parentId: '',
					tenantId: this.ruleForm.tenantId
				}
			}).then(res=>{
				this.ruleForm.provinceList = res.data;
			})	
		},
		//获取城市
		getCityList(item){
			if(item != ""){
				this.$provider.get('/wg-system/depart/baseAreaList',{
					params:{
						level: 2,
						parentId: item,
						tenantId: this.ruleForm.tenantId
					}
				}).then(res=>{
					this.ruleForm.cityList = res.data;
				})	
			}
		},
		//获取区县
		getDistrictList(item){
			if(item != ""){
				this.$provider.get('/wg-system/depart/baseAreaList',{
					params:{
						level: 3,
						parentId: item,
						tenantId: this.ruleForm.tenantId
					}
				}).then(res=>{
					this.ruleForm.districtList = res.data;
				})	
			}
		},
		//新增  编辑  确定
		submitForm(formName) {
			this.$refs[formName].validate((valid) => {
				if (valid) {
					if (this.dialogTitle == "部门新增") {
						this.$provider.post('/wg-system/depart/set', {
							name: this.ruleForm.name,
							tenantId: this.ruleForm.tenantId,
							provinceId: this.ruleForm.provinceId,
							cityId: this.ruleForm.cityId,
							areaId: this.ruleForm.areaId,
							status: this.ruleForm.status,
						}).then(res => {
							if (res.code == 200) {
								this.$message({
									message: '新增成功',
									type: 'success'
								});
								this.dialogVisible = false; //关闭弹框
								this.getPageList();
							} else {
								this.$message.error('新增失败');
								return false;
							}
						})
					} else if (this.dialogTitle == "部门编辑") {
						this.$provider.post('/wg-system/depart/set', {
							id: this.ruleForm.id,
							name: this.ruleForm.name,
							tenantId: this.ruleForm.tenantId,
							provinceId: this.ruleForm.provinceId,
							cityId: this.ruleForm.cityId,
							areaId: this.ruleForm.areaId,
							status: this.ruleForm.status,
						}).then(res => {
							if (res.code == 200) {
								this.$message({
									message: '编辑成功',
									type: 'success'
								});
								this.dialogVisible = false; //关闭弹框
								this.getPageList();
							} else {
								this.$message.error('编辑失败');
								return false;
							}
						})
					}
				} else {
					console.log('error submit!!');
					return false;
				}
			});
		},
  }
};
</script>

<style scoped="scoped"></style>
